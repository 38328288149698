<template>
  <footer class="relative bg-white">
    <div class="bg-ceruleanBlue pb-10">
      <div class="container py-8 max-w-[1200px] px-3 lg:px-10 sm:mx-auto">
        <div class="flex flex-wrap sm:justify-between justify-center">
          <div class="w-full sm:w-1/2 pl-4 sm:pl-8 sm-pt:0">
            <ul
              class="flex flex-col flex-wrap list-none sm:justify-end justify-center text-white"
            >
              <li>
                <a :href="`${marketingBaseUrl}/experts`"
                  ><span class="py-3 md:py-1 block"
                    >For Academic Experts</span
                  ></a
                >
              </li>
              <li>
                <a
                  :href="`${marketingBaseUrl}/contact-us-to-collaborate-with-academia`"
                  ><span class="py-3 md:py-1 block">Contact Us</span></a
                >
              </li>
              <li>
                <a :href="`${webBaseUrl}/auth/register`"
                  ><span class="py-3 md:py-1 block">Sign up</span></a
                >
              </li>
              <li>
                <a :href="`${webBaseUrl}/auth/login`"
                  ><span class="py-3 md:py-1 block">Log in</span></a
                >
              </li>
            </ul>
            <ul
              class="flex flex-col flex-wrap list-none sm:justify-end justify-center mt-5 text-white"
            >
              <li>
                <a href="https://notedsource.trustshare.com/" target="_blank"
                  ><span class="py-3 md:py-1 block"
                    >Security & Compliance</span
                  ></a
                >
              </li>
            </ul>
          </div>
          <div class="w-full sm:w-1/2 px-4 sm:px-8 text-white text-right">
            <div>
              <a
                :href="`${marketingBaseUrl}`"
                class="text-white text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase"
              >
                <div class="flex ml-[-40px]">
                  <svg
                    width="275"
                    height="49"
                    viewBox="0 0 275 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53.3857 19.6655H57.0385L67.1092 37.3088L70.1091 43.116H70.2778C70.1018 40.2901 69.8304 37.1239 69.8304 34.187V19.6655H73.1898V47.7247H69.537L59.4663 30.074L56.4664 24.2816H56.2977C56.4737 27.1223 56.7451 30.111 56.7451 33.0478V47.7321H53.3857V19.6655Z"
                      fill="white"
                    />
                    <path
                      d="M77.9868 37.3609C77.9868 30.4294 82.4831 26.4272 87.5367 26.4272C92.5904 26.4272 97.0867 30.422 97.0867 37.3609C97.0867 44.2999 92.5904 48.2354 87.5367 48.2354C82.4831 48.2354 77.9868 44.2407 77.9868 37.3609ZM93.478 37.3609C93.478 32.5969 91.0795 29.3567 87.5294 29.3567C83.9794 29.3567 81.5809 32.5969 81.5809 37.3609C81.5809 42.125 83.9794 45.306 87.5294 45.306C91.0721 45.306 93.478 42.1176 93.478 37.3609Z"
                      fill="white"
                    />
                    <path
                      d="M102.382 41.2964V29.8079H99.3164V27.1448L102.536 26.945L102.954 21.1157H105.866V26.945H111.448V29.8079H105.866V41.363C105.866 43.9004 106.681 45.3725 109.057 45.3725C109.791 45.3725 110.737 45.0914 111.404 44.8103L112.079 47.466C110.927 47.8655 109.541 48.2354 108.258 48.2354C103.871 48.2354 102.39 45.4317 102.39 41.3038L102.382 41.2964Z"
                      fill="white"
                    />
                    <path
                      d="M113.971 37.3609C113.971 30.5995 118.489 26.4272 123.235 26.4272C128.494 26.4272 131.457 30.237 131.457 36.1773C131.457 36.9171 131.399 37.6642 131.296 38.1747H117.426C117.675 42.5762 120.345 45.4317 124.277 45.4317C126.25 45.4317 127.878 44.7881 129.404 43.7672L130.651 46.0753C128.839 47.2663 126.646 48.228 123.844 48.228C118.35 48.228 113.979 44.1815 113.979 37.3609H113.971ZM128.377 35.6595C128.377 31.4946 126.521 29.2309 123.301 29.2309C120.404 29.2309 117.815 31.576 117.397 35.6595H128.384H128.377Z"
                      fill="white"
                    />
                    <path
                      d="M135.052 37.3605C135.052 30.6213 139.269 26.4269 143.971 26.4269C146.362 26.4269 147.939 27.3368 149.743 28.8311L149.597 25.2654V17.2612H153.088V47.7172H150.205L149.912 45.2612H149.795C148.196 46.8739 145.995 48.2276 143.545 48.2276C138.389 48.2276 135.052 44.2773 135.052 37.3531V37.3605ZM149.597 42.4427V31.5534C147.836 29.9777 146.252 29.3933 144.572 29.3933C141.279 29.3933 138.66 32.5965 138.66 37.3309C138.66 42.3095 140.707 45.276 144.316 45.276C146.223 45.276 147.873 44.3365 149.604 42.4427H149.597Z"
                      fill="white"
                    />
                    <path
                      d="M158.362 44.0704L160.482 41.6217C162.484 43.7153 165.264 45.0986 168.161 45.0986C171.814 45.0986 173.978 43.264 173.978 40.5417C173.978 37.6788 171.961 36.7615 169.335 35.6075L165.337 33.8321C162.748 32.715 159.704 30.7325 159.704 26.612C159.704 22.4915 163.408 19.1626 168.462 19.1626C171.763 19.1626 174.696 20.6051 176.655 22.6395L174.777 24.9327C173.075 23.3127 171.029 22.2992 168.462 22.2992C165.345 22.2992 163.276 23.8823 163.276 26.3975C163.276 29.0976 165.704 30.1111 167.882 31.0432L171.843 32.7742C175.063 34.1724 177.579 36.1031 177.579 40.2754C177.579 44.7066 173.948 48.25 168.095 48.25C164.186 48.25 160.79 46.6447 158.362 44.0852V44.0704Z"
                      fill="white"
                    />
                    <path
                      d="M181.151 37.3609C181.151 30.4294 185.647 26.4272 190.701 26.4272C195.755 26.4272 200.251 30.422 200.251 37.3609C200.251 44.2999 195.755 48.2354 190.701 48.2354C185.647 48.2354 181.151 44.2407 181.151 37.3609ZM196.642 37.3609C196.642 32.5969 194.244 29.3567 190.693 29.3567C187.143 29.3567 184.745 32.5969 184.745 37.3609C184.745 42.125 187.143 45.306 190.693 45.306C194.244 45.306 196.642 42.1176 196.642 37.3609Z"
                      fill="white"
                    />
                    <path
                      d="M204.41 40.1126V26.9449H207.901V39.654C207.901 43.5229 209.06 45.18 211.788 45.18C213.894 45.18 215.353 44.1295 217.304 41.6735V26.9375H220.796V47.7174H217.913L217.62 44.4624H217.502C215.595 46.7261 213.534 48.2278 210.71 48.2278C206.361 48.2278 204.41 45.4019 204.41 40.1052V40.1126Z"
                      fill="white"
                    />
                    <path
                      d="M225.812 26.945H228.695L228.988 30.7326H229.106C230.536 28.0916 232.649 26.4346 234.966 26.4346C235.876 26.4346 236.514 26.5529 237.167 26.8636L236.514 29.9484C235.81 29.7265 235.34 29.6081 234.519 29.6081C232.781 29.6081 230.697 30.8731 229.311 34.387V47.7323H225.82V26.9524L225.812 26.945Z"
                      fill="white"
                    />
                    <path
                      d="M238.568 37.3609C238.568 30.4294 243.211 26.4272 248.485 26.4272C251.191 26.4272 253.061 27.5517 254.514 28.8685L252.731 31.1913C251.528 30.0965 250.26 29.3567 248.646 29.3567C244.912 29.3567 242.184 32.5969 242.184 37.3609C242.184 42.125 244.766 45.306 248.558 45.306C250.443 45.306 252.108 44.3812 253.399 43.2346L254.91 45.5945C253.061 47.2589 250.722 48.2354 248.25 48.2354C242.763 48.2354 238.583 44.2407 238.583 37.3609H238.568Z"
                      fill="white"
                    />
                    <path
                      d="M257 37.3609C257 30.5995 261.519 26.4272 266.264 26.4272C271.523 26.4272 274.487 30.237 274.487 36.1773C274.487 36.9171 274.428 37.6642 274.325 38.1747H260.455C260.705 42.5762 263.374 45.4317 267.306 45.4317C269.279 45.4317 270.907 44.7881 272.433 43.7672L273.68 46.0753C271.868 47.2663 269.675 48.228 266.873 48.228C261.379 48.228 257.008 44.1815 257.008 37.3609H257ZM271.406 35.6595C271.406 31.4946 269.55 29.2309 266.33 29.2309C263.433 29.2309 260.844 31.576 260.426 35.6595H271.413H271.406Z"
                      fill="white"
                    />
                    <path
                      d="M7.41118 38.2483L16.2276 23.897L1.63135 18.0751L3.23034 13.2074L18.5528 17.4093L19.653 0.764648H24.8534L25.9536 17.1356L41.276 13.2074L42.875 18.0751L28.2641 23.9044L37.0952 38.2557L32.7677 41.4293L22.4036 27.9287L11.7461 41.4293L7.41852 38.2557L7.41118 38.2483Z"
                      fill="#2856E8"
                      stroke="white"
                      stroke-miterlimit="10"
                    />
                  </svg>
                </div>
              </a>
              <p class="text-sm mt-5">
                222 Broadway, 22nd Floor<br />
                New York, NY 10038<br />
                <a class="underline" href="tel:650.542.9670">650.542.9670</a>
              </p>
              <p class="text-sm mt-5">Copyright © {{ date }}</p>
              <p>
                <a :href="`${marketingBaseUrl}/terms`"
                  ><span class="py-3 md:py-1 underline">Terms</span></a
                >
                &nbsp;|&nbsp;
                <a :href="`${marketingBaseUrl}/privacy`"
                  ><span class="py-3 md:py-1 underline">Privacy</span></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
      webBaseUrl: import.meta.env.VITE_WEB_BASE_URL,
      marketingBaseUrl: import.meta.env.VITE_BASE_URL,
    };
  },
  mounted() {
    if (import.meta.env.VITE_GOOGLE_ADS_ID && this.$hj) {
      const gclidPromise = new Promise((resolve) => {
        window.gtag(
          "get",
          import.meta.env.VITE_GOOGLE_ADS_ID,
          "gclid",
          resolve,
        );
      });
      gclidPromise.then((gclid) => {
        if (gclid) {
          this.$hj("event", "google_cpc");
        }
      });
    }
    if (this.$hj) {
      this.$hj("event", `session_referrer_${window.document.referrer}`);
    }
  },
};
</script>
