<template>
  <div ref="wholeContainer" class="container w-full min-w-full h-full mt-10]">
    <div class="fixed w-full min-w-full h-full bg-white">&nbsp;</div>
    <div class="w-full overflow-y-auto">
      <div id="google_onetap"></div>
      <cookies />
      <navbar-marketing :for-experts="forExperts" />
      <main>
        <section class="relative w-full z-10">
          <router-view />
          <footer-marketing />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { utils } from "common-frontend";
import NavbarMarketing from "../components/Navbars/NavbarMarketing.vue";
import FooterMarketing from "../components/Footers/FooterMarketing.vue";
import Cookies from "../components/Cookies.vue";

const { keysToCamel } = utils;

export default {
  components: {
    NavbarMarketing,
    FooterMarketing,
    Cookies,
  },
  props: {
    forExperts: Boolean,
  },
  data() {
    return {
      opacity: 1,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    if (window.doNotTrack) {
      // eslint-disable-next-line
      console.log("internal user; not tracked");
    }
    /* if (!import.meta.env.SSR) {
      this.$api
        .attemptAccessTokenRefresh(true)
        .catch(() => {})
        .finally(() => {
          setTimeout(() => this.setUpGoogleAuth(), 500);
        });
    } */
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      const height = window.innerHeight;
      const scrollTop = Math.min(window.innerHeight, window.scrollY);
      this.opacity = 1 - (scrollTop / height) * 0.8;
    },
    handleGoogleClientId(data) {
      const payload = {
        id_token: data.credential,
        personType: this.personType,
        referralCode: this.referralCode,
      };
      this.$store.commit("setIsCheckingAuth", true);
      return axios
        .post(
          `${import.meta.env.VITE_API_BASE_URL}/consume-google-token/`,
          payload,
          { withCredentials: true },
        )
        .then((res) => {
          const data2 = keysToCamel(res.data);
          this.$store.dispatch("loginViaToken", {
            hashedId: data2.hashedId,
            token: data2.passwordResetCode,
          });
        });
    },
    setUpGoogleAuth() {
      if (window.google) {
        const self = this;
        window.handleGoogleClientId = (data) => {
          self.handleGoogleClientId(data);
        };
        window.google.accounts.id.initialize({
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
          callback: window.handleGoogleClientId,
          auto_select: true,
          cancel_on_tap_outside: true,
          context: "use",
        });
        if (!this.$store.getters.isPersonLoggedIn) {
          window.google.accounts.id.prompt();
        }
      }
    },
  },
};
</script>
