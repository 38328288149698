// layouts

import Marketing from "../layouts/Marketing.vue";
import PublicProfile from "../layouts/PublicProfile.vue";

const routes = [
  {
    name: "PublicProfile",
    path: "/me/",
    component: PublicProfile,
    children: [
      {
        name: "ProfileDetail",
        path: "/me/:slugOrHashedId",
        component: () => import("../views/ProfileDetail.vue"),
        props: { showCollaborationInquiryModal: false },
      },
      {
        name: "ProfileDetailCollaborationInquiry",
        path: "/me/:slugOrHashedId/inquire",
        component: () => import("../views/ProfileDetail.vue"),
        props: { showCollaborationInquiryModal: true },
      },
    ],
  },
  {
    name: "ExpertAreas",
    path: "/expert-areas/",
    component: PublicProfile,
    children: [
      {
        name: "SolutionsByDiscipline",
        path: "/solutions/by-discipline/:discipline",
        component: () => import("../views/SolutionsByDiscipline.vue"),
      },
      {
        name: "ExpertAreaPublicProfileList",
        path: "/expert-areas/:slug",
        component: () => import("../views/ExpertAreaPublicProfileList.vue"),
      },
    ],
  },
  {
    name: "ExpertsFrom",
    path: "/experts-from/",
    component: PublicProfile,
    children: [
      {
        name: "ExpertInstitutionPublicProfileList",
        path: "/experts-from/:slug",
        component: () =>
          import("../views/ExpertInstitutionPublicProfileList.vue"),
      },
    ],
  },
  {
    name: "ResearchCommunities",
    path: "/research-communities",
    component: Marketing,
    children: [
      {
        name: "ResearchCommunityDetail",
        path: "/research-communities/:slug",
        component: () => import("../views/ResearchCommunityDetail.vue"),
      },
    ],
  },
];

export default routes;
