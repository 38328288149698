<template>
  <div
    v-if="!hasAgreed"
    class="fixed mt-[calc(100vh-250px)] sm:mt-[calc(100vh-150px)] w-full z-60 pointer-events-none max-w-[600px]"
  >
    <div class="container mx-auto max-w-[1200px] py-3 px-4">
      <div class="flex flex-wrap justify-between">
        <div class="w-full py-4 px-4 bg-white shadow-2xl">
          <div class="flex justify-between">
            <div class="w-full w-auto py-4 px-4 bg-white">
              <p class="pointer-events-auto text-sm">
                Notice: We use cookies and similar technologies to provide
                necessary website functionality, help personalize content, and
                analyze our traffic. By using our website you agree to our
                <router-link class="underline" to="/privacy"
                  >Privacy Policy</router-link
                >
                and cookie usage.
              </p>
            </div>
            <div class="py-4 px-4 bg-white text-right">
              <button
                aria-label="Agree to Cookie Policy"
                class="bg-chartreuseYellow px-4 py-2 pointer-events-auto"
                @click="agreeToCookies()"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasAgreed: true,
    };
  },
  mounted() {
    setTimeout(() => this.syncAgreement(), 50);
  },
  methods: {
    syncAgreement() {
      this.hasAgreed = !!(localStorage && localStorage.hasAgreed);
    },
    agreeToCookies() {
      if (localStorage) {
        localStorage.hasAgreed = true;
        this.syncAgreement();
      }
    },
  },
};
</script>
