<template>
  <div ref="wholeContainer" class="container w-full min-w-full h-full mt-10]">
    <div
      class="fixed w-full min-w-full h-full bg-porcelain bg-no-repeat bg-cover bg-center"
    >
      &nbsp;
    </div>
    <div class="w-full overflow-y-auto">
      <cookies />
      <navbar-marketing />
      <main>
        <section
          class="fixed w-full z-20 overflow-y-auto mt-[70px] h-[calc(100vh-70px)]"
        >
          <router-view />
          <footer-marketing />
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import NavbarMarketing from "../components/Navbars/NavbarMarketing.vue";
import FooterMarketing from "../components/Footers/FooterMarketing.vue";
import Cookies from "../components/Cookies.vue";

export default {
  components: {
    NavbarMarketing,
    FooterMarketing,
    Cookies,
  },
  data() {
    return {
      opacity: 1,
    };
  },
};
</script>
