<template>
  <div>
    <nav
      class="bg-white top-0 h-[70px] fixed z-10 w-full flex flex-wrap navbar-expand-lg items-center"
      :class="{
        'z-50': resourcesMenuOpen || howItWorksMenuOpen || teamsWeServeMenuOpen,
      }"
    >
      <div
        class="container flex justify-between max-w-full sm:max-w-[1200px] mx-auto h-12 items-center px-5"
      >
        <div class="self-start shrink-0 px-2">
          <a
            :href="marketingBaseUrl"
            class="text-white text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase mt-1"
          >
            <img
              src="/img/notedsource-logo.svg?reload=true"
              width="181"
              height="33"
              alt="NotedSource"
            />
          </a>
        </div>
        <div class="block sm:hidden">
          <button aria-label="Mobile Navigation Menu" @click="toggleMenu()">
            <div class="hamburger">
              <div class="hamburger-line"></div>
              <div class="hamburger-line"></div>
              <div class="hamburger-line"></div>
            </div>
          </button>
        </div>
        <div class="hidden w-full sm:block mt-3">
          <div class="flex justify-between pl-5 items-center">
            <div class="flex">
              <a
                class="hidden md:inline"
                :href="`${marketingBaseUrl}/how-to-collaborate-with-academia`"
                @mouseenter="showHowItWorksMenu()"
                @mouseleave="hideHowItWorksMenu()"
              >
                <div class="flex items-center mr-2 pr-2 md:pr-5 cursor-pointer">
                  <span class="text-[15px] mr-1">How&nbsp;It&nbsp;Works</span>
                  <img
                    src="data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7.5 7L14 1' stroke='%23141414' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A"
                  />
                </div>
                <div
                  class="how-it-works-menu absolute bg-white shadow-md w-[200px] py-2"
                  :class="{ hidden: !howItWorksMenuOpen }"
                >
                  <a :href="`${marketingBaseUrl}/source-experts`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Source</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/onboard-experts`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Onboard</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/manage-experts`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Manage</h6>
                    </div>
                  </a>
                </div>
              </a>
              <a
                class="hidden xl:inline"
                :href="`${marketingBaseUrl}/solutions-academia-research-collaboration-platform`"
                @mouseenter="showTeamsWeServeMenu()"
                @mouseleave="hideTeamsWeServeMenu()"
              >
                <div class="flex items-center mr-2 pr-2 md:pr-5 cursor-pointer">
                  <span class="text-[15px] mr-1">Teams We Serve</span>
                  <img
                    src="data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7.5 7L14 1' stroke='%23141414' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A"
                  />
                </div>
                <div
                  class="teams-we-serve-menu absolute bg-white shadow-md w-[200px] py-2"
                  :class="{ hidden: !teamsWeServeMenuOpen }"
                >
                  <a :href="`${marketingBaseUrl}/general-r-d`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">General R&D</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/dei-l-d`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">DEI & L&D</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/strategy-innovation`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Strategy & Innovation</h6>
                    </div>
                  </a>
                </div>
              </a>
              <div
                @mouseenter="showResourcesMenu()"
                @mouseleave="hideResourcesMenu()"
              >
                <div
                  class="flex items-center mr-2 pr-2 md:pr-5 cursor-pointer justify-center"
                >
                  <h6 class="text-[15px] mr-1">Resources</h6>
                  <img
                    src="data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7.5 7L14 1' stroke='%23141414' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A"
                  />
                </div>
                <div
                  class="resources-menu absolute bg-white shadow-md w-[200px] py-2"
                  :class="{ hidden: !resourcesMenuOpen }"
                >
                  <a :href="`${marketingBaseUrl}/resources/events`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Events</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/resources`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Blog</h6>
                    </div>
                  </a>
                  <a :href="`${marketingBaseUrl}/resources/case-study`">
                    <div
                      class="px-5 py-2 flex items-center cursor-pointer hover:text-ceruleanBlue"
                    >
                      <h6 class="text-[15px]">Case Studies</h6>
                    </div>
                  </a>
                </div>
              </div>
              <a class="hidden md:inline" :href="`${marketingBaseUrl}/experts`">
                <div class="flex items-center mr-2 pr-2 md:pr-5 cursor-pointer">
                  <span class="text-[15px]"> Join Our Research Network </span>
                </div>
              </a>
            </div>
            <template v-if="$store.getters.isCheckingAuth">
              <i class="fas fa-circle-notch fa-spin mr-3 text-blueGray-300"></i>
            </template>
            <template v-else-if="!$store.getters.isPersonLoggedIn">
              <div class="flex">
                <a :href="`${webBaseUrl}`">
                  <div
                    class="flex items-end rounded-lg cursor-pointer border-2 border-ceruleanBlue px-4 text-ceruleanBlue bg-porcelain hover:bg-ceruleanBlue hover:text-white transition-all duration-500 font-bold mr-4"
                  >
                    <h6>Find Experts</h6>
                  </div>
                </a>
                <a :href="`${webBaseUrl}`">
                  <div
                    class="flex items-end rounded-lg cursor-pointer border-2 border-ceruleanBlue px-4 text-white bg-ceruleanBlue hover:text-white transition-all duration-500 font-bold"
                  >
                    <h6>Sign In</h6>
                  </div>
                </a>
              </div>
            </template>
            <template v-else>
              <div class="mb-3 mt-2 pt-2 hidden md:block">
                <div class="mx-auto max-w-[200px]">
                  <div class="flex items-center">
                    <user-dropdown
                      :allow-dropdown="true"
                      :profile-image-size="30"
                    />
                    <div class="ml-3 overflow-x-hidden">
                      <p class="block text-sm">
                        Hello, {{ $store.getters.person.firstName }}
                      </p>
                      <p
                        class="text-xs text-blueGray-400 max-w-full overflow-x-hidden text-ellipsis"
                      >
                        {{ $store.getters.person.calc_EmailAddress }}
                      </p>
                      <p
                        v-if="$store.getters.isImpersonating"
                        class="text-xs text-black hidden sm:block whitespace-nowrap bg-orange-200 px-1 py-0 mb-2 overflow-x-hidden text-ellipsis"
                      >
                        <em
                          >Impersonated by
                          {{ $store.getters.isImpersonating }}</em
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </nav>
    <div
      :class="{ 'not-shown': !menuOpen }"
      class="nav-menu fixed w-[100vh] h-[100vh] top-0 bg-white"
    >
      <div class="absolute left-[calc(100vw_-_90px)]">
        <a href="#" aria-label="Toggle Menu" @click="toggleMenu()">
          <div class="hamburger">
            <div class="hamburger-x hamburger-x-left"></div>
            <div class="hamburger-x hamburger-x-right"></div>
          </div>
        </a>
      </div>
      <div class="flex flex-col mt-[100px]">
        <a :href="marketingBaseUrl" @click="toggleMenu()">
          <div class="flex items-center cursor-pointer mb-5 px-10">
            <span class="text-2xl">Home</span>
          </div>
        </a>
        <a
          :href="`${marketingBaseUrl}/how-to-collaborate-with-academia`"
          @click="toggleMenu()"
        >
          <div class="flex items-center cursor-pointer mb-5 px-10">
            <span class="text-2xl">How It Works</span>
          </div>
        </a>
        <a
          :href="`${marketingBaseUrl}/solutions-academia-research-collaboration-platform`"
          @click="toggleMenu()"
        >
          <div class="flex items-center cursor-pointer mb-5 px-10">
            <span class="text-2xl">Solutions</span>
          </div>
        </a>
        <div class="items-center cursor-pointer mb-5 px-10">
          <h6 class="text-2xl">Resources</h6>
          <div class="resources-menu mt-5 ml-5">
            <a
              :href="`${marketingBaseUrl}/resources/events`"
              @click="toggleMenu()"
            >
              <div class="flex items-center cursor-pointer mb-5">
                <h6 class="text-2xl">Events</h6>
              </div>
            </a>
            <a :href="`${marketingBaseUrl}/resources`" @click="toggleMenu()">
              <div class="flex items-center cursor-pointer mb-5">
                <h6 class="text-2xl">Blog</h6>
              </div>
            </a>
            <a
              :href="`${marketingBaseUrl}/resources/case-study`"
              @click="toggleMenu()"
            >
              <div class="flex items-center cursor-pointer mb-5">
                <h6 class="text-2xl">Case Studies</h6>
              </div>
            </a>
          </div>
        </div>
        <a :href="`${marketingBaseUrl}/experts`" @click="toggleMenu()">
          <div class="flex items-center cursor-pointer mb-5 px-10">
            <span class="text-2xl">For Academic Experts</span>
          </div>
        </a>
        <template v-if="!$store.getters.isPersonLoggedIn">
          <hr class="mb-4" />
          <a :href="`${webBaseUrl}/auth/register`" @click="toggleMenu()">
            <div class="flex items-center cursor-pointer mb-5 px-10">
              <h6 class="text-2xl rounded-lg">Sign&nbsp;Up</h6>
            </div>
          </a>
          <a :href="`${webBaseUrl}`" @click="toggleMenu()">
            <div class="flex items-center cursor-pointer mb-5 px-10">
              <h6 class="text-2xl bg-chartreuseYellow px-5 py-2 rounded-lg">
                Login
              </h6>
            </div>
          </a>
        </template>
        <template v-else>
          <div
            class="w-full mb-3 mt-2 block md:hidden bg-blueGray-100 py-3 px-10"
          >
            <p
              v-if="$store.getters.isImpersonating"
              class="text-2xl text-black hidden sm:inline-block bg-orange-200 px-1 py-0 mb-2"
            >
              <em>Impersonated by {{ $store.getters.isImpersonating }}</em>
            </p>
            <div class="flex items-center">
              <div class="overflow-x-hidden">
                <p class="block text-2xl">
                  Hello, {{ $store.getters.person.firstName }}
                </p>
                <p
                  class="text-blueGray-400 max-w-full overflow-x-hidden text-ellipsis"
                >
                  {{ $store.getters.person.calc_EmailAddress }}
                </p>
              </div>
            </div>
          </div>
          <a
            v-if="$store.getters.person?.personType?.value !== 'ADMIN'"
            :href="`${webBaseUrl}/account/edit-profile`"
            @click="toggleMenu()"
          >
            <div class="flex items-center cursor-pointer mb-5 px-10">
              <h6 class="text-2xl rounded-lg">Edit&nbsp;Profile</h6>
            </div>
          </a>
          <a
            v-if="$store.getters.person?.personType?.value === 'ADMIN'"
            :href="`${adminBaseUrl}/auth/login?saml=success`"
            @click="toggleMenu()"
          >
            <div class="flex items-center cursor-pointer mb-5 px-10">
              <h6 class="text-2xl rounded-lg">NotedSource Admin</h6>
            </div>
          </a>
          <a :href="`${webBaseUrl}/auth/logout`" @click="toggleMenu()">
            <div class="flex items-center cursor-pointer mb-5 px-10">
              <h6 class="text-2xl rounded-lg">Log&nbsp;Out</h6>
            </div>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import UserDropdown from "../UserDropdown.vue";

export default {
  components: {
    UserDropdown,
  },
  props: {
    forExperts: Boolean,
  },
  data() {
    return {
      webBaseUrl: import.meta.env.VITE_WEB_BASE_URL,
      marketingBaseUrl: import.meta.env.VITE_BASE_URL,
      adminBaseUrl: import.meta.env.VITE_ADMIN_BASE_URL,
      menuOpen: false,
      resourcesMenuOpen: false,
      teamsWeServeMenuOpen: false,
      howItWorksMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    showResourcesMenu() {
      this.resourcesMenuOpen = true;
    },
    hideResourcesMenu() {
      this.resourcesMenuOpen = false;
    },
    showHowItWorksMenu() {
      this.howItWorksMenuOpen = true;
    },
    hideHowItWorksMenu() {
      this.howItWorksMenuOpen = false;
    },
    showTeamsWeServeMenu() {
      this.teamsWeServeMenuOpen = true;
    },
    hideTeamsWeServeMenu() {
      this.teamsWeServeMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hamburger {
  margin: 25px;
  width: 40px;
  top: 5px;
  position: relative;

  .hamburger-line {
    width: 40px;
    height: 5px;
    background-color: #cccccc;
    border-radius: 2px;
    margin-bottom: 8px;
  }

  .hamburger-x {
    width: 40px;
    height: 5px;
    background-color: #bbbbbb;
    border-radius: 2px;
    position: relative;

    &.hamburger-x-left {
      transform: rotate(45deg);
    }
    &.hamburger-x-right {
      transform: rotate(-45deg);
      top: -4px;
    }
  }
}
.nav-menu {
  transition: 0.5s ease-in-out;
  opacity: 1;
  left: 0vh;
  pointer-events: all;
  z-index: 50;

  &.not-shown {
    opacity: 0;
    left: 100vh;
    pointer-events: none;
  }
}
</style>
