<template>
  <div v-on-click-outside="onClickOutside">
    <a
      ref="btnDropdownRef"
      class="text-blueGray-500 block"
      href="#"
      @click="toggleDropdown"
    >
      <div class="items-center flex">
        <i class="fa-solid fa-caret-down mr-2 opacity-50"></i>
        <profile-image
          :size="profileImageSize"
          :img-url="
            avatarUrl($store.getters.person.profile, $store.getters.person)
          "
        />
      </div>
    </a>
    <div ref="popoverDropdownRef">
      <div
        class="bg-white text-base z-50 list-none text-left min-w-48 border uppercase mt-2"
        :class="{
          hidden: !dropdownPopoverShow,
          block: dropdownPopoverShow,
        }"
      >
        <a
          v-if="$store.getters.person?.personType?.value === 'EXPERT'"
          :href="`${webBaseUrl}/account/edit-profile`"
          class="text-sm px-5 py-3 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        >
          Edit Profile
        </a>
        <a
          v-if="$store.getters.person?.personType?.value === 'CLIENT'"
          :href="`${webBaseUrl}/account/w`"
          class="text-sm px-5 py-3 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        >
          View Dashboard
        </a>
        <a
          v-if="$store.getters.person?.personType?.value === 'ADMIN'"
          :href="`${adminBaseUrl}/auth/login?saml=success`"
          class="text-sm px-5 py-3 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        >
          NotedSource Admin
        </a>
        <a
          :href="`${webBaseUrl}/auth/logout`"
          class="text-sm px-5 py-3 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100"
        >
          Logout
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { vOnClickOutside } from "@vueuse/components";
import { utils } from "common-frontend";
import ProfileImage from "./ProfileImage.vue";

const { avatarUrl } = utils;

export default {
  components: {
    ProfileImage,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    logoutRedirect: String,
    allowDropdown: Boolean,
    profileImageSize: Number,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      avatarUrl,
      popper: null,
      adminBaseUrl: import.meta.env.VITE_ADMIN_BASE_URL,
      webBaseUrl: import.meta.env.VITE_WEB_BASE_URL,
    };
  },
  methods: {
    toggleDropdown(event) {
      if (this.allowDropdown) {
        event.preventDefault();
        if (this.dropdownPopoverShow) {
          this.dropdownPopoverShow = false;
        } else {
          this.dropdownPopoverShow = true;
          if (!this.popper) {
            this.popper = createPopper(
              this.$refs.btnDropdownRef,
              this.$refs.popoverDropdownRef,
              {
                placement: "bottom-start",
              },
            );
          }
          // stops the issue where the popover is in a random place
          window.scroll({ top: window.scrollY + 1 });
          window.scroll({ top: window.scrollY - 1 });
        }
      }
    },
    onClickOutside() {
      this.dropdownPopoverShow = false;
    },
  },
};
</script>
